import Head from 'next/head';
import dynamic from 'next/dynamic';
import AboutMe from '../components/AboutMe';
import ProjectSectionHome from '../components/ProjectSectionHome';
import { getAboutMeHome, getHomepage } from '../queries/pages';
import { Layout } from '../components/Layouts';
import { HomeLayout } from '../components/Layouts';
import QuotesSection from '../components/QuotesSection';
import React from 'react';
import { ContactMeHomeSection } from '../components/ContactMe';
import * as CONSTANTS from '../utils/constants';
import { HeaderHome } from '../components/Header';

// import { Helmet } from "react-helmet";
// import { helmetSettingsFromMetadata } from "../lib/site";
// import usePageMetadata from "../components/hooks/usePageMetadata";
import parse from 'html-react-parser';

export default function Custom404() {
  return (
    <div className='f4'>
      <div className='section'>
        <h1 className='error'>404</h1>
        <div className='page'>
          Ooops!!! The page you are looking for is not found
        </div>
        <a className='back-home' href='/'>
          Back to home
        </a>
      </div>
    </div>
  );
}
